/**
 * Validate MCBT
 * @param mcbt - email address
 */
const validateMCBT = (mcbt: string): string | boolean => {
    if (
        mcbt && !/^\S*$/i.test(mcbt)
    ) {
        return 'No spaces allowed';
    }

    return true;
};

export default validateMCBT;
