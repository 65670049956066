import {nonvoMutationName, voMutationName} from './copy';

export type VideoVersion = 'translation' | 'voiceOver';

const videoVersionMappings: Record<VideoVersion, {color: string; name: string}> = {
    translation: {
        color: '#8F00FF',
        name: nonvoMutationName,
    },
    voiceOver: {
        color: '#FF9900',
        name: voMutationName,
    },
};

export default videoVersionMappings;
