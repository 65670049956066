import {
    Dispatch,
    useState,
    SetStateAction,
    useMemo,
} from 'react';
import clsx from 'clsx';
import {useQuery} from '@apollo/client';
import {
    Checkbox,
    Modal,
    SearchInput,
} from '../../components/core';
import ExportDownload from '../../components/partials/videoExport/exportDownload';
import {isInText} from '../../helpers/search';
import * as styles from './videosExport.module.css';
import {ExcelTemplate, GetExportColumnsDocument} from '../../graphql-types';

interface IVideosExportModalProps<T> {
    setModal: (value: T | null) => void,
    setMutatiosToExport: Dispatch<SetStateAction<string[] | 'all'>>,
    mutationIds: string[] | 'all',
    allRowsCount: number,
    getFilter: () => any,
}

type PredefinedExports = {
    label: string,
    description: string,
    template: string,
}[];

const predefinedExports: PredefinedExports = [
    {
        label: 'List of URL',
        description: 'Lightweight Excel sheet with video names and URLs',
        template: ExcelTemplate.UrlsList,
    }, {
        label: 'Youtube bulk import',
        description: 'Excel with YouTube CSV structure',
        template: 'youtubeBulkImport',
    },
];

const VideosExportModal = <T extends string>({
    setModal,
    mutationIds,
    allRowsCount,
    getFilter,
    setMutatiosToExport,
}: IVideosExportModalProps<T>) => {
    const {data: columnsData} = useQuery(GetExportColumnsDocument);
    const [columnIds, setColumnIds] = useState<string[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const totalRowsCount = useMemo(() => {
        if (mutationIds === 'all') {
            return allRowsCount;
        }

        return mutationIds.length;
    }, [allRowsCount, mutationIds]);
    const mutationIdsToExport = useMemo(() => {
        if (mutationIds === 'all') {
            return [];
        }

        return mutationIds;
    }, [mutationIds]);

    const exports = predefinedExports.map(({label, description, template}, idx) => (
        <ExportDownload
            key={`export-row-${idx}`}
            label={label}
            description={description}
            template={template}
            mutationIds={mutationIdsToExport}
            getFilter={getFilter}
        />
    ));

    return (
        <Modal
            title={`Download Excel export with ${totalRowsCount} videos`}
            closeModal={() => {
                setModal(null);
                setMutatiosToExport([]);
            }}
        >
            {exports}
            <div className={styles.divider}>
                <hr />
            </div>
            <ExportDownload
                label="Custom Export"
                description="Define your own data size"
                columnIds={columnIds}
                mutationIds={mutationIdsToExport}
                disabled={columnIds.length === 0}
                disabledTooltipText="You must select at least one column to export"
                getFilter={getFilter}
            />
            <div className={styles.valuesWrapper}>
                <div className={styles.search}>
                    <SearchInput
                        query={searchQuery}
                        placeholder="Search parameters"
                        handleChange={setSearchQuery}
                    />
                </div>
                <ul className={styles.propertiesWrapper}>
                    {columnsData && columnsData.exportColumns.map(column => {
                        if (!column) {
                            return null;
                        }

                        const {title, id} = column;
                        const isInQuery = isInText(searchQuery, title || '');

                        return (
                            <li
                                key={`dimension${title}-${id}`}
                                className={clsx(
                                    styles.propertyItem,
                                    !isInQuery && styles.disabled,
                                )}
                            >
                                <Checkbox
                                    name={title}
                                    label={title}
                                    value={id}
                                    checked={columnIds.includes(id)}
                                    disabled={!isInQuery}
                                    handleChange={() => {
                                        if (columnIds.includes(id)) {
                                            setColumnIds(prevState => prevState.filter(item => item !== id));

                                            return;
                                        }

                                        setColumnIds(prevState => [...prevState, id]);
                                    }}
                                />
                            </li>
                        );
                    })}
                </ul>
            </div>
        </Modal>
    );
};

export default VideosExportModal;
