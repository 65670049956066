const forceDownload = async (link: string) => {
    const anchor = document.createElement('a');

    anchor.href = link;
    anchor.download = link;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
};

export default forceDownload;
