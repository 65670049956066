import {ColumnSortingSettings} from '../interfaces/table';

// eslint-disable-next-line import/prefer-default-export
export const sortingSettings = <T>(sorting: ColumnSortingSettings<T>, key: T) => (sorting.enabled.includes(key)
    ? {
        isSorted: sorting.active?.column === key,
        isSortedDesc: sorting.active?.desc,
        sortingKey: key,
        setSorting: sorting.setSorting,
    } : undefined);
