import {useEffect} from 'react';
import {
    useForm,
    FieldValues,
} from 'react-hook-form';
import {useApolloClient, useMutation, useQuery} from '@apollo/client';
import {navigate} from 'gatsby';
import {
    GetAddVideoAttributesDocument,
    CheckMcbtExistsDocument,
    AddVideoDocument,
} from '../../graphql-types';
import {
    Button,
    Input,
    Modal,
} from '../../components/core';
import Form from '../../components/core/form/form';
import SelectBox from '../../components/core/form/input/selectBox';
import Spacer from '../../components/core/spacer/spacer';
import validateMCBT from '../../helpers/validators/mcbt';
import toastify from '../../helpers/toast/toastify';
import {getVideoVersionLabel} from '../../helpers/pages/video';
import * as styles from './addVideo.module.css';

interface IAddVideoModalProps<T> {
    setModal: (value: T | null) => void,
    currentChannel: string,
}

const AddVideoModal = <T extends string>({
    setModal,
    currentChannel,
}: IAddVideoModalProps<T>) => {
    const [addVideo, {loading}] = useMutation(AddVideoDocument);
    const {data: videoAttributes} = useQuery(GetAddVideoAttributesDocument);
    const apolloClient = useApolloClient();
    const formMethods = useForm<FieldValues>({
        mode: 'onChange',
        defaultValues: {
            mcbt: '',
            version: '',
            channel: '',
        },
    });
    const {
        setValue,
        control,
        handleSubmit,
        formState: {isValid},
    } = formMethods;

    const onSubmit = (formData: FieldValues) => {
        const newData = {
            mcbt: formData.mcbt,
            version: formData.version,
            channelId: formData.channel,
        };

        addVideo({
            variables: {
                input: newData,
            },
            onCompleted(data) {
                if (!data) {
                    toastify({type: 'error', text: 'There was an error on creation'});

                    return;
                }

                navigate(`/videos/${data.addVideo?.channel.id}/${data.addVideo?.id}`, {
                    state: {
                        toast: {
                            type: 'success',
                            text: 'Video created',
                        },
                    },
                });
            },
            onError(err) {
                toastify({type: 'error', text: `There was an error on creation: ${err.message}`});
            },
            refetchQueries: ['getMutations'],
            onQueryUpdated(observableQuery) {
                return observableQuery.refetch();
            },
        });
    };

    useEffect(() => {
        if (!videoAttributes) {
            return;
        }

        setValue('channel', videoAttributes.channels.find(channel => channel.id === currentChannel)?.id);
    }, [
        currentChannel,
        setValue,
        videoAttributes,
    ]);

    return (
        <Modal
            title="Create a new video"
            closeModal={() => setModal(null)}
            buttons={(
                <Button
                    text="Create"
                    disabled={!isValid || loading}
                    size="medium"
                    onClick={handleSubmit(onSubmit)}
                    loading={loading}
                />
            )}
        >
            <Form methods={formMethods}>
                <Form.Row>
                    <Form.Column className={styles.formCol}>
                        <Input
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'MCBT is required',
                                },
                                validate: async value => {
                                    const patternValidation = validateMCBT(value);

                                    if (typeof patternValidation === 'string') {
                                        return patternValidation;
                                    }

                                    const {data: {videoMcbtExists}} = await apolloClient.query({
                                        query: CheckMcbtExistsDocument,
                                        variables: {mcbt: value},
                                    });

                                    if (videoMcbtExists?.exists === true) {
                                        return `This MCBT is already in use: ${videoMcbtExists.video?.id}`;
                                    }

                                    return true;
                                },
                            }}
                            title="MCBT/AID"
                            name="mcbt"
                            type="text"
                        />
                    </Form.Column>
                </Form.Row>
                <Form.Row>
                    <Form.Column className={styles.formCol}>
                        <SelectBox
                            optionsValues={
                                videoAttributes
                                    ? videoAttributes.versionAttributes.map(version => ({
                                        id: version?.id,
                                        value: version?.name,
                                        label: getVideoVersionLabel(version?.name),
                                    }))
                                    : []
                            }
                            control={control}
                            required={true}
                            canType={false}
                            title="Video version"
                            placeholder="Choose video version"
                            controllerName="version"
                        />
                        <Spacer height="2rem"/>
                    </Form.Column>
                </Form.Row>
                <Form.Row>
                    <Form.Column className={styles.formCol}>
                        <SelectBox
                            optionsValues={
                                videoAttributes
                                    ? videoAttributes.channels.map(channel => ({
                                        id: channel.id,
                                        value: channel.id,
                                        label: channel.name,
                                    }))
                                    : []
                            }
                            control={control}
                            required={true}
                            canType={false}
                            title="Channel"
                            placeholder="Choose the channel"
                            controllerName="channel"
                        />
                    </Form.Column>
                </Form.Row>
            </Form>
        </Modal>
    );
};

export default AddVideoModal;
